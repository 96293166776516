<template>
  <v-card flat class="flex pb-8" outlined min-height="200">
    <v-card flat color="transparent" class="pa-4">
      <v-row align="center" justify="space-between" class="mb-2" no-gutters>
        <span class="font-weight-bold text-h6">
          Settings and Tools
        </span>
        <v-btn v-show="false"></v-btn>
      </v-row>
      <v-row class="" no-gutters>
        <span class="font-weight-light text-body-1">
          The following items affect how the platform is used within the
          organization.
        </span>
      </v-row>
    </v-card>

    <v-divider class=""></v-divider>
    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Organization Display Name
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-3">
      <v-row no-gutters>
        <v-flex xs12 sm8 md6>
          Enter the name of the organization. This name is displayed on the left
          hand navigation bar and in other areas to identify the entire
          organization.
        </v-flex>
        <v-layout row class="mx-2">
          <v-layout justify-end row class="mr-2">
            <v-form ref="partnerNameForm">
              <v-text-field
                v-if="partner"
                @blur="nameBlur"
                @change="nameChanged = true"
                :rules="nameRules"
                style="max-width:450px;"
                v-model="partner.name"
                dense
                label="Organization Name"
                outlined
              />
            </v-form>
          </v-layout>
        </v-layout>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Dashboard Metrics
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-0">
      <v-row no-gutters>
        <v-row no-gutters justify="space-between" class="mx-0">
          <v-flex xs12 sm8 md6>
            The metrics selected will be display for every patient on the main
            dashboard. All metrics recorded for a patient are available within
            the patient chart.
          </v-flex>
          <v-flex xs6 sm4 md3>
            <v-layout row justify-center class="mx-2">
              <v-select
                ref="addMetricSelect"
                @change="metricAdded"
                v-model="metricToAdd"
                :items="metrics"
                dense
                label="Add Metric"
                outlined
              />
              <!-- <v-btn depressed @click="addThreshold"><v-icon class="mr-1">mdi-file-document-box-plus</v-icon> add Metric</v-btn> -->
            </v-layout>
          </v-flex>
        </v-row>
      </v-row>
    </v-card-text>

    <v-flex xs12>
      <v-card style="width:100%" color="transparent" flat outlined>
        <v-simple-table style="width: 100%; background-color: transparent">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Dashboard Metric</th>
                <th class="text-center">Remove</th>
              </tr>
            </thead>
            <tbody
              v-if="
                configuration &&
                  configuration.displayedDashboardMetrics &&
                  configuration.displayedDashboardMetrics.length > 0
              "
            >
              <tr
                v-for="(item, index) in configuration.displayedDashboardMetrics"
                :key="index"
              >
                <td class="mt-2 mb-2">
                  {{ item }}
                </td>
                <td style="max-width: 80px; width: 80px;">
                  <v-layout row justify-center>
                    <v-btn color="red" @click="removeMetric(item)" icon
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-layout
          class="mx-0 mt-4 mb-4"
          v-if="
            !configuration ||
              !configuration.displayedDashboardMetrics ||
              configuration.displayedDashboardMetrics.length === 0
          "
          style="min-width: 100%; opacity: 0.4"
          row
          justify-center
        >
          No Metrics Displayed
        </v-layout>
      </v-card>
    </v-flex>

    <v-divider></v-divider>

    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Automatic Time Counting
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-2">
      <v-row no-gutters justify="space-between" class="mx-0">
        <v-col xs="12" sm="6" md="6">
          If Automatic Time Counting is enabled, qualified users' time will be
          tracked from the moment they open a patient chart to the moment they
          leave. Users will not be required to start or stop their own time.
        </v-col>
        <v-col xs="6" sm="6" md="6">
          <v-row justify="end" align="center" no-gutters>
            <v-switch
              @change="autoTimeSwitch"
              color="primary"
              v-model="autoCounting"
              :label="timeCountingEnabled"
              inset
              hide-details
            ></v-switch>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider class="mt-3"></v-divider>

    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Chart Inactivity Timeout
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-2">
      <v-row no-gutters justify="space-between" class="mx-0">
        <v-col xs="12" sm="6" md="6">
          If a user is in a chart and inactive, the system will move the user to
          the main dashboard after a period of time. This helps minimize
          accidental QHP time accrual if a chart is unintentionally left open.
          However, if patient interaction is occuring outside of the system, it
          may be helpful to extend the timeout to prevent the chart from
          closing.
        </v-col>
        <v-col xs="6" sm="6" md="6">
          <v-row justify="end" no-gutters>
            <v-radio-group
              @change="inactivityChange()"
              row
              v-model="chartInactivity"
            >
              <v-radio label="5 Minutes" :value="5"></v-radio>
              <v-radio label="15 Minutes" :value="15"></v-radio>
              <v-radio label="30 Minutes" :value="30"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider class="mt-3"></v-divider>
    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Password Reset Tool
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-0">
      <v-row no-gutters justify="space-between" class="mx-0">
        <v-flex xs12 sm8 md6>
          Enter the email address of the user needing a password reset.
          Submitting an email address here will send an email with password
          reset instructions to the identified user.
        </v-flex>
        <v-layout row class="mx-2">
          <v-layout justify-end row>
            <v-form ref="resetPasswordForm">
              <v-text-field
                :rules="emailRules"
                style="max-width:250px;"
                hide-details
                v-model="emailToReset"
                dense
                label="Email"
                outlined
              />
            </v-form>
            <v-btn
              class="ml-2 mr-3"
              @click="sendReset()"
              style="margin-top:2px;"
              depressed
              >Send</v-btn
            >
          </v-layout>
        </v-layout>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import consts from '@/consts'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      selectedStartDate: 1,
      autoCounting: false,
      chartNotesOnVitals: false,
      partnerObject: {},
      eventRulesArray: [],
      rulesTable: 0,
      emailRules: [v => !!v || 'E-mail is required'],
      nameRules: [v => !!v || 'Name is required'],
      emailToReset: null,
      metricToAdd: null,
      nameChanged: false,
      showLookup: false,
      conditionSearch: null,
      conditionOptions: [],
      selectedCondition: null,
      menu: false,
      chartInactivity: 5,
    }
  },
  components: {},
  watch: {
    showLookup(val) {
      if (val) {
        // this.$nextTick(() => (this.$refs.search.focus()))
        this.$nextTick(() => this.$refs.search.activateMenu())
      }
    },
    conditionSearch(val) {
      if (val) {
        this.lookupProblem({ terms: val }).then(data => {
          this.conditionOptions = data
        })
      }
    },
    selectedCondition(val) {
      if (val) {
        this.addProblemToFilter(val)
      }
    },
  },
  computed: {
    ...mapState('auth', [
      'rpmPartner',
      'configuration',
      'user',
      'partner',
      'appTheme',
    ]),
    metrics() {
      //eventTypesDisplayNameDictionary
      let metricsToReturn = []
      Object.entries(consts.metricList).forEach(metric => {
        const [name, details] = metric
        metricsToReturn.push({value: name, text: details.longName})
      })
      return metricsToReturn
    },
    startDateOptions() {
      let options = []
      for (let i = 1; i < 29; i++) {
        options.push(i)
      }
      return options
    },
    chartNotesOnVitalsEnabled() {
      if (this.chartNotesOnVitals) {
        if (this.autoCounting) {
          return 'Enabled'
        }
      }
      return 'Disabled'
    },
    timeCountingEnabled() {
      if (this.autoCounting) {
        return 'Enabled'
      }
      return 'Disabled'
    },
    addMetricOptions() {
      let options = []
      Object.keys(consts.metricList).forEach(metricName => {
        if (
          !this.configuration.displayedDashboardMetrics ||
          !this.configuration.displayedDashboardMetrics.includes(metricName)
        ) {
          options.push(metricName)
        }
      })
      return options
    },
    partnerConditionFilters() {
      if (this.configuration.dashboardFilters) {
        return this.configuration.dashboardFilters
      }
      return []
    },
  },
  methods: {
    ...mapActions('auth', [
      'updatePartnerSettings',
      'setPartnerThresholdSettings',
      'updatePartnerObject',
    ]),
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('patient', ['lookupProblem']),
    nameBlur() {
      if (this.nameChanged) {
        this.updatePartnerObject()
          .then(() => {
            bus.$emit('toast', { type: 'success', text: 'Updated' })
            this.nameChanged = false
          })
          .catch(error => {
            console.log(error)
            bus.$emit('toast', { type: 'error', text: 'Error Updating' })
          })
      }
    },
    sendReset() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.resetPassword(this.emailToReset)
          .then(() => {
            this.$refs.resetPasswordForm.reset()
            this.$refs.resetPasswordForm.resetValidation()
            bus.$emit('toast', { type: 'success', text: 'Email Sent' })
          })
          .catch(error => {
            console.log(error)
            this.$refs.resetPasswordForm.reset()
            this.$refs.resetPasswordForm.resetValidation()
            bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
          })
      }
    },
    addProblemToFilter(problem) {
      this.menu = false
      this.$refs.search.blur()
      this.$nextTick(() => (this.selectedCondition = null))
      this.$nextTick(() => (this.conditionSearch = null))
      this.$nextTick(() => (this.conditionOptions = null))
      let conditionList = this.configuration.dashboardFilters
      if (!conditionList) {
        conditionList = []
      }
      let canPush = true
      conditionList.forEach(element => {
        if (element === problem.compound) {
          canPush = false
        }
      })
      if (canPush) {
        conditionList.push(problem.compound)
        this.updatePartnerSettings({
          partner: this.user.partnerID,
          update: { dashboardFilters: conditionList },
        })
      }
    },
    removeProblemFromFilter(problem) {
      let index = this.configuration.dashboardFilters.indexOf(problem)
      if (index > -1) {
        this.configuration.dashboardFilters.splice(index, 1)
        this.updatePartnerSettings({
          partner: this.user.partnerID,
          update: { dashboardFilters: this.configuration.dashboardFilters },
        })
      }
    },
    metricAdded() {
      console.log('you picked a metric')
      if (this.configuration) {
        if (this.configuration.displayedDashboardMetrics) {
          this.configuration.displayedDashboardMetrics.push(this.metricToAdd)
          this.$refs.addMetricSelect.reset()
          this.updatePartnerSettings({
            partner: this.user.partnerID,
            update: {
              displayedDashboardMetrics: this.configuration
                .displayedDashboardMetrics,
            },
          })
        } else {
          this.configuration.displayedDashboardMetrics = []
          this.configuration.displayedDashboardMetrics.push(this.metricToAdd)
          this.$refs.addMetricSelect.reset()
          this.updatePartnerSettings({
            partner: this.user.partnerID,
            update: {
              displayedDashboardMetrics: this.configuration
                .displayedDashboardMetrics,
            },
          })
        }
      } else {
        this.configuration = {}
        this.configuration.displayedDashboardMetrics = []
        this.configuration.displayedDashboardMetrics.push(this.metricToAdd)
        this.$refs.addMetricSelect.reset()
        this.updatePartnerSettings({
          partner: this.user.partnerID,
          update: {
            displayedDashboardMetrics: this.configuration
              .displayedDashboardMetrics,
          },
        })
      }
    },
    removeMetric(metric) {
      this.$refs.addMetricSelect.reset()
      let index = this.configuration.displayedDashboardMetrics.indexOf(metric)
      if (index > -1) {
        this.configuration.displayedDashboardMetrics.splice(index, 1)
        this.updatePartnerSettings({
          partner: this.user.partnerID,
          update: {
            displayedDashboardMetrics: this.configuration
              .displayedDashboardMetrics,
          },
        })
      }
    },
    addThreshold() {
      this.$refs.addThreshold.open()
    },
    submitThreshold(payload) {
      console.log('rpm partner')
      console.log(this.rpmPartner.partner)
      // inset this into the current thresholds...
      if (this.rpmPartner && this.rpmPartner.thresholds) {
        this.rpmPartner.thresholds[payload.metricName] =
          payload[payload.metricName]
      } else if (this.rpmPartner) {
        this.rpmPartner.thresholds = {}
        this.rpmPartner.thresholds[payload.metricName] =
          payload[payload.metricName]
      }
      if (this.rpmPartner) {
        let dataToSet = {}
        dataToSet.partner = this.rpmPartner.partner
        dataToSet.thresholds = this.rpmPartner.thresholds
        this.setPartnerThresholdSettings(dataToSet)
      }
    },
    removeThreshold(item) {
      if (this.rpmPartner && this.rpmPartner.thresholds) {
        delete this.rpmPartner.thresholds[item.metric]
      } else if (this.rpmPartner) {
        this.rpmPartner.thresholds = {}
      }
      if (this.rpmPartner) {
        let dataToSet = {}
        dataToSet.partner = this.rpmPartner.partner
        dataToSet.thresholds = this.rpmPartner.thresholds
        this.setPartnerThresholdSettings(dataToSet)
      }
    },
    parseRules() {
      let rulesArray = []
      if (this.rpmPartner && this.rpmPartner.thresholds) {
        let keys = Object.keys(this.rpmPartner.thresholds)
        keys.forEach(key => {
          console.log(this.rpmPartner.thresholds[key])
          let item = { metric: key }
          if (this.rpmPartner.thresholds[key].above) {
            item.operator = 'Above'
            if (this.rpmPartner.thresholds[key].above.value) {
              item.value = this.rpmPartner.thresholds[key].above.value
            } else if (this.rpmPartner.thresholds[key].above.systolicValue) {
              item.value =
                this.rpmPartner.thresholds[key].above.systolicValue +
                '/' +
                this.rpmPartner.thresholds[key].above.diastolicValue
            }
          } else {
            item.operator = 'Below'
            if (this.rpmPartner.thresholds[key].below.value) {
              item.value = this.rpmPartner.thresholds[key].below.value
            } else if (this.rpmPartner.thresholds[key].below.systolicValue) {
              item.value =
                this.rpmPartner.thresholds[key].below.systolicValue +
                '/' +
                this.rpmPartner.thresholds[key].below.diastolicValue
            }
          }
          this.rulesTable = this.rulesTable + 1
          rulesArray.push(item)
          console.log('pushing to rules array')
          console.log(rulesArray)
          this.rulesArray = rulesArray
        })
        console.log('pushing to rules array')
        console.log(rulesArray)
        this.rulesArray = rulesArray
      } else {
        this.rulesArray = rulesArray
      }
    },
    parseRulesObjectObject() {
      let rulesArray = []
      if (this.partnerObject.defaultPatientEventRules) {
        let keys = Object.keys(this.partnerObject.defaultPatientEventRules)
        keys.forEach(key => {
          console.log(this.partnerObject.defaultPatientEventRules[key])
          let item = { metric: key }
          if (this.partnerObject.defaultPatientEventRules[key].above) {
            item.operator = 'Above'
            if (this.partnerObject.defaultPatientEventRules[key].value) {
              item.value = this.partnerObject.defaultPatientEventRules[key]
            } else if (
              this.partnerObject.defaultPatientEventRules[key].systolicValue
            ) {
              item.value =
                this.partnerObject.defaultPatientEventRules[key].systolicValue +
                '/' +
                this.partnerObject.defaultPatientEventRules[key].diastolicValue
            }
          } else {
            item.operator = 'Below'
            if (this.partnerObject.defaultPatientEventRules[key].value) {
              item.value = this.partnerObject.defaultPatientEventRules[key]
            } else if (
              this.partnerObject.defaultPatientEventRules[key].systolicValue
            ) {
              item.value =
                this.partnerObject.defaultPatientEventRules[key].systolicValue +
                '/' +
                this.partnerObject.defaultPatientEventRules[key].diastolicValue
            }
          }

          rulesArray.push(item)
        })
      }
      this.eventRulesArray = rulesArray
    },
    startDateChanged() {
      console.log(this.selectedStartDate)
      this.updatePartnerSettings({
        partner: this.user.partnerID,
        update: { startDate: this.selectedStartDate },
      })
    },
    autoTimeSwitch() {
      this.updatePartnerSettings({
        partner: this.user.partnerID,
        update: { autoCounting: this.autoCounting },
      })
      this.configuration.autoCounting = this.autoCounting
    },
    inactivityChange() {
      this.updatePartnerSettings({
        partner: this.user.partnerID,
        update: { chartInactivity: this.chartInactivity },
      })
      this.configuration.chartInactivity = this.chartInactivity
    },
    chartNotesSwitch() {
      this.updatePartnerSettings({
        partner: this.user.partnerID,
        update: { chartNotesOnVitals: this.chartNotesOnVitals },
      })
      this.configuration.chartNotesOnVitals = this.chartNotesOnVitals
    },
  },
  mounted() {
    this.parseRules()
    if (this.configuration) {
      if (this.configuration.autoCounting) {
        this.autoCounting = this.configuration.autoCounting
      }
      if (this.configuration.chartInactivity) {
        this.chartInactivity = this.configuration.chartInactivity
      }
      if (this.configuration.chartNotesOnVitals) {
        this.chartNotesOnVitals = this.configuration.chartNotesOnVitals
      }
    }
  },
}
</script>

<style></style>
