<template>
  <v-container class="pa-4 grey lighten-5" fluid>
    <portal to="appBarContentLeft">
      <span class="headline font-weight-light">{{ partner.name }}</span>
    </portal>
    <v-col class="pa-0" cols="12">
      <v-card
        flat
        class="flex mb-4"
        outlined
        min-height="200"
        v-if="user && user.partnerID && user.partnerRoles.admin"
      >
        <v-card flat color="transparent" class="pa-4 pb-1">
          <v-row align="center" justify="space-between" class="mb-2" no-gutters>
            <span class="font-weight-bold text-h6"
              >Organization User Accounts</span
            >
            <v-btn color="primary" text depressed @click="addUser()">
              <v-icon left>mdi-plus</v-icon>add user
            </v-btn>
          </v-row>
        </v-card>

        <v-divider></v-divider>
        <v-data-table
          :items="rawGroupMembers"
          item-key="displayName"
          class="elevation-0"
          :headers="headers"
          :loading="updatingMembers"
          :items-per-page="5"
          height="357"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.firstName }}
                        {{ item.lastName }}</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        item.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td>
                  <v-layout row justify-center align-center>
                    <v-checkbox
                      hide-details
                      class="pa-0 ml-2 mt-0 mb-0"
                      color="primary"
                      @change="updatePermissions(item)"
                      :disabled="!userCanManageMembers"
                      v-model="item.partnerRoles.admin"
                    ></v-checkbox>
                  </v-layout>
                </td>
                <td style="max-width:120px; width:120px;">
                  <v-layout row class="mx-0" justify-center>
                    <v-checkbox
                      hide-details
                      class="pa-0 ml-2 mt-0 mb-0"
                      color="primary"
                      @change="updatePermissions(item)"
                      :disabled="!userCanManageMembers"
                      v-model="item.partnerRoles.qhp"
                    ></v-checkbox>
                  </v-layout>
                </td>
                <td style="max-width: 80px; width: 80px;">
                  <v-layout row justify-center>
                    <v-btn
                      :disabled="!userCanManageMembers"
                      color="red"
                      @click="removeUser(item)"
                      icon
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>

      <v-layout class="patient-page" justify-start>
        <AddUserToGroupDialog ref="addUser" />
        <remove-user-from-group-dialog
          @removeUser="deleteUser"
          ref="removeUser"
        />
        <v-flex xs12>
          <v-expand-transition>
            <v-card
              flat
              class="flex mb-4"
              color="grey lighten-4"
              min-height="200"
              v-if="user && user.partnerID && user.partnerRoles.admin"
              v-show="inviteList.length > 0"
            >
              <v-card flat color="transparent" class="pa-4 pb-1">
                <v-row
                  align="center"
                  justify="space-between"
                  class="mb-2"
                  no-gutters
                >
                  <span class="font-weight-bold text-h6">Pending Invites</span>
                </v-row>
              </v-card>

              <v-divider></v-divider>
              <v-data-table
                class="elevation-0 grey lighten-4"
                style="max-width: 100%"
                :items="inviteList"
                item-key="displayName"
                :headers="inviteHeaders"
                :hide-default-header="isMobile"
                hide-default-footer
                :loading="updatingInvited"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.email }}</td>
                      <td>{{ item.expires }}</td>
                      <td style="max-width: 80px; width: 80px;">
                        <v-layout row justify-center>
                          <v-btn
                            :disabled="!userCanManageMembers"
                            color="red"
                            @click="deleteInviteHere(item)"
                            icon
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-layout>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-expand-transition>
        </v-flex>
      </v-layout>
      <partner-settings
        v-if="showSettings"
        :partner="user.partnerID"
        class="mb-4"
      />
      <partner-settings-reminder-texts class="mb-4" />
      <threshold-settings v-if="showSettings" class="mb-4" />
      <org-patient-groups v-show="false" class="mb-4" />
      <org-note-templates class="mb-4" />
      <org-text-templates />
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import AddUserToGroupDialog from '@/components/dialogs/AddUserToGroupDialog'
import RemoveUserFromGroupDialog from '@/components/dialogs/RemoveUserFromGroupDialog'
import PartnerSettings from '@/components/PartnerSettings'
import ThresholdSettings from '@/components/ThresholdSettings'
import bus from '@/core/helpers/bus'
import OrgNoteTemplates from '../components/OrgNoteTemplates.vue'
import OrgTextTemplates from '../components/OrgTextTemplates.vue'
import OrgPatientGroups from '../components/OrgPatientGroups.vue'
import PartnerSettingsReminderTexts from '../components/PartnerSettingsReminderTexts.vue'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      resetLoading: false,
      email: null,
      emailRules: [v => !!v || 'E-mail is required'],
      updatingMembers: false,
      rawGroupMembers: [],
      updatingInvited: false,
      inviteList: [],
    }
  },
  components: {
    AddUserToGroupDialog,
    RemoveUserFromGroupDialog,
    PartnerSettings,
    // PartnerDataExport,
    ThresholdSettings,
    OrgNoteTemplates,
    OrgTextTemplates,
    OrgPatientGroups,
    PartnerSettingsReminderTexts,
  },
  computed: {
    ...mapState('auth', [
      'user',
      'currentUserGroups',
      'selectedUserGroup',
      'selectedUserGroupMembers',
      'appTheme',
      'partner',
      'rpmPartner',
    ]),
    userCanManageMembers() {
      if (this.user && this.user.partnerID && this.user.partnerRoles.admin) {
        return true
      }
      return false
    },
    showSettings() {
      if (this.user && this.user.partnerID && this.user.partnerRoles.admin) {
        return true
      }
      return false
    },
    headers() {
      return [
        {
          text: 'User',
          align: 'start',
          sortable: false,
          value: 'displayName',
        },
        {
          text: 'Admin',
          align: 'center',
          sortable: false,
        },
        {
          text: 'QHP',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Remove',
          align: 'center',
          sortable: false,
        },
      ]
    },
    inviteHeaders() {
      return [
        {
          text: 'Recipient',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Expiration',
          align: 'start',
          sortable: false,
          value: 'expires',
        },
        {
          text: 'Delete',
          align: 'center',
          sortable: false,
        },
      ]
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
  },
  watch: {
    selectedUserGroupMembers() {
      this.updatingMembers = false
    },
  },
  methods: {
    ...mapActions('auth', [
      'resetPassword',
      'updateUserName',
      'getUserGroups',
      'getUserGroup',
      'updatePartnerUserPermissions',
      'passwordreset',
      'refreshUserList',
      'removeUserFromPartner',
      'refreshPartnerInviteList',
      'deleteInvite',
    ]),
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    removeUser(userToEdit) {
      this.$refs.removeUser.open({
        user: userToEdit,
        userGroup: this.$route.params.id,
      })
    },
    deleteInviteHere(invite) {
      this.deleteInvite(invite._id)
        .then(() => {
          bus.$emit('refreshInviteList')
          this.toast('success', 'Invite Removed')
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please Try Again Later')
        })
    },
    sendReset() {
      if (this.$refs.pwreset.validate()) {
        this.resetPassword(this.email)
          .then(() => {
            bus.$emit('toast', { type: 'success', text: 'Email Sent' })
          })
          .catch(error => {
            console.log(error)
            bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
          })
      }
    },
    updatePermissions(user) {
      let payload = { userID: user._id, partnerRoles: user.partnerRoles }
      this.updatePartnerUserPermissions(payload)
        .then(() => {
          this.toast('success', 'Permissions Updated')
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please Try Again Later')
        })
    },
    addUser() {
      this.$refs.addUser.open()
    },
    deleteUser(user) {
      let payload = { userID: user._id }

      this.removeUserFromPartner(payload)
        .then(() => {
          this.toast('success', 'User Removed')
          this.updatingMembers = true
          this.refreshUserList(this.$route.params.id).then(data => {
            this.updatingMembers = false
            this.rawGroupMembers = data
          })
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please Try Again Later')
        })
    },
    editPermissions(user) {
      this.$refs.editPermissions.open({
        userToEdit: user,
      })
    },
  },
  mounted() {
    bus.$on('refreshMemberList', () => {
      this.updatingMembers = true
      this.refreshUserList(this.$route.params.id).then(data => {
        this.updatingMembers = false
        this.rawGroupMembers = data
      })
    })
    bus.$on('refreshInviteList', () => {
      this.updatingInvited = true
      this.refreshPartnerInviteList(this.$route.params.id).then(data => {
        this.updatingInvited = false
        this.inviteList = data
      })
    })
    this.updatingMembers = true
    this.refreshUserList(this.$route.params.id).then(data => {
      this.updatingMembers = false
      this.rawGroupMembers = data
    })
    this.updatingInvited = true
    this.refreshPartnerInviteList(this.$route.params.id).then(data => {
      this.updatingInvited = false
      this.inviteList = data
    })
  },
}
</script>
<style>
.headerStyle {
  background-color: green;
}
</style>
