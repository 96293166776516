<template>
  <v-card flat class="flex" outlined min-height="200">
    <v-card flat color="transparent" class="pa-4">
      <v-row align="center" justify="space-between" class="mb-2" no-gutters>
        <span class="font-weight-bold text-h6">
          Chart Note Templates
        </span>
        <v-dialog
          v-model="saveTemplateDialog"
          width="500"
          ref="saveTemplateDialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              text
              @click="savedTemplateTitle = null"
              ><v-icon left>mdi-plus</v-icon>
              New template
            </v-btn>
          </template>

          <v-card>
            <v-row
              align="center"
              no-gutters
              justify="space-between"
              class="font-weight-bold text-h6 px-2"
              style="height:60px;"
            >
              <v-text-field
                ref="templateNameText"
                v-model="savedTemplateTitle"
                style="font-size:20px;"
                solo
                flat
                hide-details
                placeholder="Note Template Title"
              >
              </v-text-field>
            </v-row>

            <v-textarea
              class="font-weight-light text-body-1 px-2"
              no-resize
              v-model="savedTemplateContent"
              hide-details
              placeholder="Note template content"
              solo
              flat
              height="150"
            >
            </v-textarea>

            <v-card-actions>
              <v-btn depressed @click="saveTemplateDialog = false">
                cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="saveTemplateWithTitle()">
                Save Template
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row class="" no-gutters>
        <span class="font-weight-light text-body-1">
          These templates allow for quick documentation in chart notes. Any
          template listed below will be available for inserting into a new note
          in a patient chart. Note templates are intended to allow for
          standardized documentation across the organization while allowing
          staff to fill in the specifics regarding each patient in the moment.
        </span>
      </v-row>
    </v-card>
    <v-row no-gutters>
      <v-simple-table style="width: 100%; background-color: transparent">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Template Name</th>
              <th class="text-left">Content</th>
              <th class="text-right">Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in savedNoteTemplates" :key="index">
              <td style="max-width: 200px; width: 200px; text-align: left">
                {{ item.title }}
              </td>
              <td>{{ item.content }}</td>
              <td style="max-width: 100px; width: 100px; text-align: right">
                <v-btn @click="permanentlyDeleteATemplate(item)" icon
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row
        class="flex"
        align="center"
        justify="center"
        v-if="savedNoteTemplates.length === 0"
      >
        <v-chip outlined class="ma-5">No saved templates</v-chip>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  data() {
    return {
      saveTemplateDialog: false,
      savedTemplateTitle: null,
      savedTemplateContent: null,
    }
  },
  watch: {
    saveTemplateDialog() {
      let vm = this

      this.$nextTick().then(() => {
        vm.$refs.templateNameText.$refs.input.focus()
      })
    },
  },
  computed: {
    ...mapState('notes', ['savedNoteTemplates']),
  },
  methods: {
    ...mapActions('notes', [
      'saveANoteTemplate',
      'deleteANoteTemplate',
      'getSavedNoteTemplates',
    ]),
    permanentlyDeleteATemplate(template) {
      this.deleteANoteTemplate(template)
    },
    saveTemplateWithTitle() {
      if (this.savedTemplateTitle !== null && this.savedTemplateTitle !== '') {
        let template = {
          title: this.savedTemplateTitle,
          content: this.savedTemplateContent,
        }
        this.saveANoteTemplate(template)
        this.saveTemplateDialog = false
        this.savedTemplateTitle = null
        this.savedTemplateContent = null
        bus.$emit('toast', { type: 'success', text: 'Template saved' })
      } else {
        bus.$emit('toast', { type: 'error', text: 'Missing template name' })
      }
    },
  },
  mounted() {},
}
</script>

<style></style>
