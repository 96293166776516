<template>
  <v-card
    :disabled="!partner.orgNumOn"
    flat
    class="flex pb-8"
    outlined
    min-height="200"
  >
    <v-card flat color="transparent" class="pa-4">
      <v-row align="center" justify="space-between" class="mb-2" no-gutters>
        <span class="font-weight-bold text-h6">
          Automated Reminder Texts
        </span>

        <v-spacer />
        <span v-if="partner.twilioPhone"
          >Practice SMS Number: {{ partner.twilioPhone }}</span
        >
        <v-switch
          class="mr-2 ml-5"
          @change="changedEnable"
          color="primary"
          v-model="automatedRemindersBoolean"
          :label="automatedRemindersLabel"
          inset
        ></v-switch>
      </v-row>
      <v-row class="" no-gutters>
        <span class="font-weight-light text-body-1">
          Automated reminder texts are designed to allow for protocol based
          messaging to be sent to patients to help with compliance. Texts are
          sent to SMS-enabled patients who took a reading 2 days prior, but
          missed the day immediately before. Texts are sent at 12:00 pm Central
          Time. In effort to not interrupt other text communication, if other
          text communication occurs with a patient within 3 hours previous to
          the scheduled send time, the patient is not sent a text.
        </span>
      </v-row>
    </v-card>
    <v-row no-gutters class="px-4">
      <v-textarea
        class="font-weight-light text-body-1"
        ref="textarea"
        no-resize
        v-model="textMessage"
        hide-details
        placeholder="Enter reminder message"
        flat
        height="150"
        outlined
        @keyup="messageChanged = true"
        :background-color="textBackground"
        @focus="textAreaHighlighted = true"
      >
      </v-textarea>
    </v-row>

    <v-dialog
      persistent
      v-model="displayWarning"
      width="500"
      ref="warningDialog"
    >
      <v-card>
        <v-toolbar flat>
          <v-row
            align="center"
            no-gutters
            justify="space-between"
            class="font-weight-bold text-h6"
          >
            Enable Reminder Texts
          </v-row>
        </v-toolbar>

        <v-card-text class="font-weight-light text-body-1 px-4">
          By enabling reminder texts, patients with an SMS phone number listed
          will receive a reminder text if they have not taken a reading for more
          than 48 hours. Texts are sent at 12:00 pm central time. This setting
          can be disabled at any time. Are you sure you would like to enable
          these texts?
        </v-card-text>

        <v-card-actions>
          <v-btn
            depressed
            @click="
              ;(displayWarning = false), (automatedRemindersBoolean = false)
            "
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            @click="submitChangesToMessage2(), (displayWarning = false)"
          >
            Enable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row style="min-height:50px;" justify="start" no-gutters class="px-4">
      <v-btn
        v-show="textAreaHighlighted"
        label
        class="mr-2 mt-2"
        @click="insertFirstNameAtCursor()"
        depressed
        color="grey lighten-3"
        ><v-icon size="20" left>mdi-code-braces</v-icon>Insert first name</v-btn
      >
      <v-btn
        v-show="textAreaHighlighted"
        label
        class="mr-2 mt-2"
        @click="insertVitalsListAtCursor()"
        depressed
        color="grey lighten-3"
        v-if="false"
        ><v-icon size="20" left>mdi-code-braces</v-icon>Insert vitals
        list</v-btn
      >
      <v-spacer />
      <v-btn
        v-if="textAreaHighlighted"
        label
        class="mr-2 mt-2"
        depressed
        color="primary"
        @click="submitChangesToMessage2(), (textAreaHighlighted = false)"
        >Save Changes</v-btn
      >
      <v-btn
        v-if="!textAreaHighlighted"
        label
        class="mr-2 mt-2"
        depressed
        color="primary"
        @click="textAreaHighlighted = true"
        >Edit Message</v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'
import get from 'lodash/get'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      textMessage: '',
      partnerObject: {},
      messageChanged: false,
      textAreaHighlighted: false,
      automatedRemindersBoolean: false,
      displayWarning: false,
    }
  },
  components: {},
  watch: {
    textAreaHighlighted(val) {
      if (val) {
        let vm = this
        this.$nextTick().then(() => {
          vm.$refs.textarea.focus()
        })
      }
    },
  },
  computed: {
    ...mapState('auth', [
      'rpmPartner',
      'configuration',
      'user',
      'partner',
      'appTheme',
    ]),
    textBackground() {
      if (this.textAreaHighlighted) {
        return 'white'
      } else {
        return null
      }
    },
    automatedRemindersLabel() {
      if (this.automatedRemindersBoolean) {
        return 'Enabled'
      }

      return 'Disabled'
    },
  },
  methods: {
    ...mapActions('auth', [
      'updatePartnerSettings',
      'setPartnerThresholdSettings',
      'updatePartnerObject',
    ]),
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('patient', ['lookupProblem']),
    changedEnable() {
      if (this.automatedRemindersBoolean) {
        this.displayWarning = true
      } else {
        this.submitChangesToMessage2()
      }
    },
    insertTemplate(template) {
      this.textMessage = template.content
    },
    insertFirstNameAtCursor() {
      this.execInsertText('{patient_first_name}')
    },
    insertVitalsListAtCursor() {
      this.execInsertText('{vitals_list}')
    },
    execInsertText(insertText) {
      if (!insertText.length) return

      const textarea = this.$refs.textarea.$refs.input
      const sentence = textarea.value

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      let positionEnd = pos

      if (textarea.selectionEnd && textarea.selectionEnd !== pos) {
        // you've chosen to replace an entire selection with a text expansion prompt
        // remove the entire word that was selected and replace it with the correct text
        positionEnd = textarea.selectionEnd
      }

      let before = sentence.substr(0, pos)
      let after = sentence.substr(positionEnd, len)

      // add a space if there isnt one?

      if (
        before.length &&
        before.slice(-1) !== null &&
        before.slice(-1) !== ' '
      ) {
        before = before + ' '
      }

      if (
        after.charAt(0).length &&
        after.charAt(0) !== ' ' &&
        after.charAt(0) !== '!' &&
        after.charAt(0) !== '.' &&
        after.charAt(0) !== '?' &&
        after.charAt(0) !== ','
      ) {
        after = ' ' + after
      }

      this.textMessage = before + insertText + after
      let vm = this
      this.$nextTick().then(() => {
        textarea.selectionStart = before.length + insertText.length
        textarea.selectionEnd = before.length + insertText.length
        vm.$refs.textarea.focus()
      })
    },
    submitChangesToMessage2() {
      if (this.partner.patientInactivitySettings) {
        if (this.partner.patientInactivitySettings.automatedSms) {
          this.partner.patientInactivitySettings.automatedSms.tmpl = this.textMessage
          this.partner.patientInactivitySettings.automatedSms.enabled = this.automatedRemindersBoolean
        } else {
          this.partner.patientInactivitySettings.automatedSms = {
            tmpl: this.textMessage,
            enabled: this.automatedRemindersBoolean,
          }
        }
      } else {
        this.partner.patientInactivitySettings = {
          automatedSms: {
            tmpl: this.textMessage,
            enabled: this.automatedRemindersBoolean,
          },
        }
      }
      this.updatePartnerObject()
        .then(() => {
          bus.$emit('toast', { type: 'success', text: 'Updated' })
          this.textAreaHighlighted = false
          this.messageChanged = false
        })
        .catch(error => {
          console.log(error)
          bus.$emit('toast', { type: 'error', text: 'Error Updating' })
        })
    },
  },
  mounted() {
    if (
      get(this, 'partner.patientInactivitySettings.automatedSms.tmpl', null)
    ) {
      this.textMessage = this.partner.patientInactivitySettings.automatedSms.tmpl
    }

    if (
      get(this, 'partner.patientInactivitySettings.automatedSms.enabled', null)
    ) {
      this.automatedRemindersBoolean = this.partner.patientInactivitySettings.automatedSms.enabled
    }
  },
}
</script>

<style></style>
