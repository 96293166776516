<template>
  <v-card flat class="flex" color="grey lighten-4" min-height="200">
      <v-card flat color="transparent" class="pa-4">
      <v-row align="center" justify="space-between" class="mb-2"  no-gutters>
      <span class="font-weight-bold text-h6" >
        Patient Groups
      </span>
      <v-dialog
        v-model="saveTemplateDialog"
        width="500"
        ref="saveTemplateDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
            v-on="on"
                    color="primary"
                    text
                    @click="savedTemplateTitle = null"
                    ><v-icon left>mdi-plus</v-icon>
                    New Group
                    </v-btn>
        </template>
  
        <v-card>
          <v-row
                        align="center"
                        no-gutters
                        justify="space-between"
                        class="font-weight-bold text-h6 px-2"
                        style="height:60px;"
                      >
                        <v-text-field ref="templateNameText" v-model="savedTemplateTitle" style="font-size:20px;" solo flat hide-details placeholder="Group Name">
          </v-text-field>
                      </v-row>

  
          <v-textarea
                  class="font-weight-light text-body-1 px-2"
                  no-resize
                  v-model="savedTemplateContent"
                  hide-details
                  placeholder="Note template content"
                  solo
                  flat
                  height="150"
                >
                </v-textarea>
  
          <v-card-actions>
            <v-btn
              depressed
              @click="saveTemplateDialog = false"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="saveTemplateWithTitle()"
            >
              Save Template
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class=""  no-gutters>
      <span class="font-weight-light text-body-1" >
        Patient groups allow for the patient population to be organized into dynamic groups for team care. Patient groups are subsets of the entire population and are defined by filters. These filters determine which patients belong to a particular group. Assigning a user to a group will set that group (or groups) as the default view for that user.
      </span>
    </v-row>
      </v-card>
    <v-row no-gutters>
    <v-simple-table 
                    style="width: 100%; background-color: transparent"
                  >
                  
                    <template v-slot:default>
                        <thead >
            <tr>
              <th class="text-left">Group Name</th>
              <th class="text-left">Filters</th>
              <th class="text-right">Delete</th>
            </tr>
          </thead>
                      <tbody >
                        <tr
                          v-for="(item, index) in patientGroups"
                            :key="index"
                        >
                          <td
                            style="max-width: 200px; width: 200px; text-align: left"
                          >
                            {{item.title}}
                          </td>
                          <td
                            >{{item.filter}}
                          </td>
                          <td
                            style="max-width: 100px; width: 100px; text-align: right"
                            ><v-btn @click="permanentlyDeleteAGroup(item)" icon><v-icon>mdi-close</v-icon></v-btn>
                          </td>
                        </tr>

                        
                      </tbody>
                      
                    </template>
                  </v-simple-table>
                  <v-row class="flex" align="center" justify="center" v-if="patientGroups.length === 0">
                            <v-chip outlined class="ma-5">No patient groups</v-chip>
                        </v-row>
    </v-row>

    
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import bus from "@/core/helpers/bus";

export default {
data() {
    return {
      saveTemplateDialog: false,
      savedTemplateTitle: null,
      savedTemplateContent: null,
    }
  },
  watch: {
    saveTemplateDialog() {
      let vm = this
      
      this.$nextTick().then(() => {
        vm.$refs.templateNameText.$refs.input.focus()
      });

      
    }
  },
    computed: {
    ...mapState("patientgroups", ["patientGroups"]),
    },
  methods: {
    ...mapActions("patientgroups", [
      "saveAPatientGroup",
      "deleteAPatientGroup",
      "getSavedNoteTemplates"
    ]),
    permanentlyDeleteAGroup(template) {
      this.deleteAPatientGroup(template)
    },
    saveTemplateWithTitle() {
      if (this.savedTemplateTitle !== null && this.savedTemplateTitle !== '') {
        let template = {title: this.savedTemplateTitle, filter: this.savedTemplateContent, users: []}
        this.saveAPatientGroup(template)
        this.saveTemplateDialog = false
        this.savedTemplateTitle = null
        this.savedTemplateContent = null
        bus.$emit("toast", { type: "success", text: "Patient group saved" });
      }
    },
  },
  mounted() {
  }
}
</script>

<style>

</style>